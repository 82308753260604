<template>
  <div id="game-4">
    <header>
      <div class="back-btn">
        <router-link to="/games">
          <i class="fas fa-chevron-left white-text"></i>
          <label>WYJDŹ</label>
        </router-link>

        <!-- Witaj {{ user.displayName }} -->
      </div>

      <div style="display:flex;align-items:center;">
        <i class="fas fa-star" style="margin-right:0.1rem;"></i> {{ userPoints }}
        <div style="margin-left:1rem;display:flex;align-items:center;" v-if="gameStarted && !gameComplete">
          <i class="fas fa-clock" style="margin:0 0.2rem 0 0;"></i> {{ userTimer }}
        </div>
        <div @click="mapOpen = !mapOpen" style="display:flex;align-items:center;"><i class="fas fa-map-marked-alt" style="margin:0 0.2rem 0 1rem;"></i> MAPA</div>
      </div>
    </header>
    <main>
      <div class="intro-screen" v-if="!gameStarted">
        <div class="box">
          <h2>Odnajduj MISIE i zdobywaj punkty.</h2>
          <p>
             
            100 MISIÓW do odnalezienia<br>
            600 punktów do zdobycia!<br><br>
            Podążaj śladami pozostawionymi na mapie!<br>
            Wygrywa drużyna, która zdobędzie największą ilość punktów.<br><br>
            Prawidłowa odpowiedź +5 pkt<br>
            Nieprawidłowa odpowiedź -1pkt<br>
            Bonus +10 pkt
          </p>
          <h3>Przygoda Wzywa! Powodzenia!</h3>
          <button v-if="userPoints == 0" @click="startGame">Start</button>
          <button v-else @click="returnGame">Wznów grę</button>
        </div>
      </div>
      <div class="menu-screen" v-if="gameStarted && selectedBear == null">
        <h3>Wybierz numer znalezionego MISIA</h3>
        <div class="nums">
          <div @click="selectBear(bear)" :class="bear.unlocked == true ? bear.color : ''" v-for="(bear, index) in bears" :key="index">{{ bear.number }}</div>
        </div>
        <button v-if="false" @click="allUnlocked()">TEST</button>
      </div>
      <div class="question-screen" v-if="gameStarted && selectedBear != null">
        <h2>Hej! Mam numer {{ selectedBear.number }}.<br>W jakim jestem kolorze?</h2>
        
        <div :class="selectedBear.unlocked == true ? selectedBear.color + '-bear bear-text' : 'bear-image'">
          <div></div>
          <h1>{{ selectedBear.number }}</h1>
        </div>
        
        <div>
          <div class="colors">
            <button @click="answerBear('red')" class="colorBtn red" :disabled="disabledColors.includes('red')"></button>
            <button @click="answerBear('green')" class="colorBtn green" :disabled="disabledColors.includes('green')"></button>
            <button @click="answerBear('blue')" class="colorBtn blue" :disabled="disabledColors.includes('blue')"></button>
            <button @click="answerBear('violet')" class="colorBtn violet" :disabled="disabledColors.includes('violet')"></button>
            <button @click="answerBear('brown')" class="colorBtn brown" :disabled="disabledColors.includes('brown')"></button>
            <button @click="answerBear('black')" class="colorBtn black" :disabled="disabledColors.includes('black')"></button>
            <button @click="answerBear('rose')" class="colorBtn rose" :disabled="disabledColors.includes('rose')"></button>
            <button @click="answerBear('yellow')" class="colorBtn yellow" :disabled="disabledColors.includes('yellow')"></button>
            <button @click="answerBear('orange')" class="colorBtn orange" :disabled="disabledColors.includes('orange')"></button>
            <button @click="answerBear('gray')" class="colorBtn gray" :disabled="disabledColors.includes('gray')"></button>
            <button @click="answerBear('white')" class="colorBtn white" :disabled="disabledColors.includes('white')"></button>
            <button @click="answerBear('gold')" class="colorBtn gold" :disabled="disabledColors.includes('gold')"></button>
          </div>
          <button @click="selectedBear = null">Wróć</button>
        </div>
      </div>
      <div class="bonus-screen" v-if="bonusQuestionActive">
        <div>
          <h1 style="color:#b9351d;">Pytanie bonusowe</h1>
          <h2 style="color:black;">
            Zdobądź <i class="fas fa-star" style="font-size: 1.2rem;color:#e4be00;"></i>+10
          </h2>
          <div class="q-box">
            <p>
              {{ selectedBonus.question }}
            </p>
          </div>
        </div>

        <div class="answer-buttons">
          <div
            v-for="(answer, index) in selectedBonus.answers"
            :key="index"
            @click="selectBonusAnswer(answer, index)"
            :class="{
              'answerButton': true,
              'correct': correct && answer === selectedBonus.correctAnswer,
              'wrong': index == wrongIndex,
              'bigger': index == selectedAnswerIndex,
            }"
          >
            {{ answer }}
          </div>
        </div>
      </div>
      <div class="end-screen" v-if="gameComplete">
        <div class="box">
          <h1>Brawo!</h1>
          <h2>Zdobyliście {{ userPoints }} pkt</h2>
          <p style="margin: 2rem 0;">Jeżeli jesteście najlepsi,<br>skontaktujemy się z Wami<br>i wręczymy nagrody.</p>
          <h3>Dziękujemy za udział w grze.<br>Zaglądajcie na nasz fanpage MiastoToGra.pl<br>i oczekujcie kolejnych wyzwań.<br>W czasie wakacji będzie ich naprawdę dużo.</h3>
          <button id="diploma-btn" @click="getDiploma()">Odbierz dyplom</button>
        </div>
      </div>
      <div class="map-screen" v-if="mapOpen">
        <img src="/game4/mapa.jpg" style="width:100%;border:3px dashed #9e4a0d;" alt="">
        <button @click="mapOpen = !mapOpen">Zamnij mapę</button>
      </div>
    </main>
    
  </div>
</template>

<script>
// Gra na dzień dziecka
import firebase from "@/firebase";

export default {
  data() {
    return {
      gameComplete: false,
      gameStarted: false,
      userPoints: 0,
      selectedBear: null,
      startDateTime: null,
      userTimer: "02:00:00",
      bears: [
        { number: 1, color: 'red', unlocked: false },
        { number: 2, color: 'rose', unlocked: false },
        { number: 3, color: 'gray', unlocked: false },
        { number: 4, color: 'black', unlocked: false },
        { number: 5, color: 'green', unlocked: false },
        { number: 6, color: 'black', unlocked: false },
        { number: 7, color: 'gray', unlocked: false },
        { number: 8, color: 'blue', unlocked: false },
        { number: 9, color: 'orange', unlocked: false },
        { number: 10, color: 'gold', unlocked: false },
        { number: 11, color: 'yellow', unlocked: false },
        { number: 12, color: 'red', unlocked: false },
        { number: 13, color: 'orange', unlocked: false },
        { number: 14, color: 'violet', unlocked: false },
        { number: 15, color: 'orange', unlocked: false },
        { number: 16, color: 'green', unlocked: false },
        { number: 17, color: 'blue', unlocked: false },
        { number: 18, color: 'rose', unlocked: false },
        { number: 19, color: 'gray', unlocked: false },
        { number: 20, color: 'gold', unlocked: false },
        { number: 21, color: 'blue', unlocked: false },
        { number: 22, color: 'rose', unlocked: false },
        { number: 23, color: 'red', unlocked: false },
        { number: 24, color: 'white', unlocked: false },
        { number: 25, color: 'white', unlocked: false },
        { number: 26, color: 'blue', unlocked: false },
        { number: 27, color: 'black', unlocked: false },
        { number: 28, color: 'rose', unlocked: false },
        { number: 29, color: 'brown', unlocked: false },
        { number: 30, color: 'gold', unlocked: false },
        { number: 31, color: 'rose', unlocked: false },
        { number: 32, color: 'red', unlocked: false },
        { number: 33, color: 'orange', unlocked: false },
        { number: 34, color: 'black', unlocked: false },
        { number: 35, color: 'yellow', unlocked: false },
        { number: 36, color: 'violet', unlocked: false },
        { number: 37, color: 'gray', unlocked: false },
        { number: 38, color: 'brown', unlocked: false },
        { number: 39, color: 'rose', unlocked: false },
        { number: 40, color: 'gold', unlocked: false },
        { number: 41, color: 'black', unlocked: false },
        { number: 42, color: 'violet', unlocked: false },
        { number: 43, color: 'black', unlocked: false },
        { number: 44, color: 'gray', unlocked: false },
        { number: 45, color: 'violet', unlocked: false },
        { number: 46, color: 'white', unlocked: false },
        { number: 47, color: 'gray', unlocked: false },
        { number: 48, color: 'red', unlocked: false },
        { number: 49, color: 'yellow', unlocked: false },
        { number: 50, color: 'gold', unlocked: false },
        { number: 51, color: 'red', unlocked: false },
        { number: 52, color: 'gray', unlocked: false },
        { number: 53, color: 'green', unlocked: false },
        { number: 54, color: 'red', unlocked: false },
        { number: 55, color: 'yellow', unlocked: false },
        { number: 56, color: 'brown', unlocked: false },
        { number: 57, color: 'white', unlocked: false },
        { number: 58, color: 'brown', unlocked: false },
        { number: 59, color: 'rose', unlocked: false },
        { number: 60, color: 'gold', unlocked: false },
        { number: 61, color: 'blue', unlocked: false },
        { number: 62, color: 'white', unlocked: false },
        { number: 63, color: 'white', unlocked: false },
        { number: 64, color: 'white', unlocked: false },
        { number: 65, color: 'brown', unlocked: false },
        { number: 66, color: 'yellow', unlocked: false },
        { number: 67, color: 'green', unlocked: false },
        { number: 68, color: 'violet', unlocked: false },
        { number: 69, color: 'orange', unlocked: false },
        { number: 70, color: 'gold', unlocked: false },
        { number: 71, color: 'violet', unlocked: false },
        { number: 72, color: 'brown', unlocked: false },
        { number: 73, color: 'blue', unlocked: false },
        { number: 74, color: 'violet', unlocked: false },
        { number: 75, color: 'yellow', unlocked: false },
        { number: 76, color: 'yellow', unlocked: false },
        { number: 77, color: 'green', unlocked: false },
        { number: 78, color: 'blue', unlocked: false },
        { number: 79, color: 'gray', unlocked: false },
        { number: 80, color: 'gold', unlocked: false },
        { number: 81, color: 'black', unlocked: false },
        { number: 82, color: 'brown', unlocked: false },
        { number: 83, color: 'blue', unlocked: false },
        { number: 84, color: 'green', unlocked: false },
        { number: 85, color: 'orange', unlocked: false },
        { number: 86, color: 'white', unlocked: false },
        { number: 87, color: 'orange', unlocked: false },
        { number: 88, color: 'blue', unlocked: false },
        { number: 89, color: 'black', unlocked: false },
        { number: 90, color: 'gold', unlocked: false },
        { number: 91, color: 'red', unlocked: false },
        { number: 92, color: 'green', unlocked: false },
        { number: 93, color: 'yellow', unlocked: false },
        { number: 94, color: 'violet', unlocked: false },
        { number: 95, color: 'brown', unlocked: false },
        { number: 96, color: 'green', unlocked: false },
        { number: 97, color: 'orange', unlocked: false },
        { number: 98, color: 'blue', unlocked: false },
        { number: 99, color: 'red', unlocked: false },
        { number: 100, color: 'gold', unlocked: false },
      ],
      bonusQuestionActive: false,
      bonusQuestions: [
        {
          id: 10,
          question: "Który z misiów uwielbia miodek, przyjaźni się z Krzysiem i Prosiaczkiem?",
          answers: ["Kubuś Puchatek", "Miś Colargol", "Miś Yogi", "Miś Czarny Nosek"]
        },
        {
          id: 20,
          question: "Który miś nosi przeciwdeszczowy, granatowy płaszcz oraz kapelusze w różnych kolorach?",
          answers: ["Miś Paddington", "Miś Uszatek", "Grafii", "Miś Guziczek"]
        },
        {
          id: 30,
          question: "Kto przyjaźni się z Boo Boo i kradnie kosze piknikowe turystom?",
          answers: ["Miś Yogi", "Kubuś Puchatek", "Niedźwiedź Baloo", "Miś Brązek"]
        },
        {
          id: 40,
          question: "Co daje gumisiom nadzwyczajne możliwości?",
          answers: ["Picie soku z gumijagód", "Jedzenie liści brzozy", "Nacieranie się magicznym kremem", "Długie spanie"]
        },
        {
          id: 50,
          question: "Jak nazywa się przyjaciel Mowgliego?",
          answers: ["Baloo", "Bunia", "Boo Boo", "Reni"]
        },
        {
          id: 60,
          question: "Gdzie mieszkał Kubuś Puchatek?",
          answers: ["w Stumilowym Lesie", "w Misiowym Lesie", "w Zielonym Lesie", "w Dębowym Lesie"]
        },
        {
          id: 70,
          question: "Który miś uwielbia śpiewać?",
          answers: ["Miś Colargol", "Miś Beniamin", "Uszatek", "Miś Wesołek"]
        },
        {
          id: 80,
          question: "Jak ma na imię miś z bajki Kung Fu Panda?",
          answers: ["Po", "Loo Loo", "Woo", "De De"]
        },
        {
          id: 90,
          question: "Kto jest Strażnikiem Wielkiej Księgi Gumisiów i nosi magiczny Gumisiowy Medalion?",
          answers: ["Bunia", "Sani", "Zami", "Kenia"]
        },
        {
          id: 100,
          question: "Z jakiego kraju pochodzi miś Paddington?",
          answers: ["Anglii", "Francji", "Holandii", "Grecji"]
        }
      ],
      selectedBonus: null,
      correct: false,
      wrongIndex: -1,
      selectedAnswerIndex: null,
      timerInterval: null,
      disabledColors: [],
      mapOpen: false,
    }
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.commit("SET_USER", user);
        this.shuffleBears();
        this.shuffleBonusQuestions()
        this.getUserData(); // Odkomentuj aby włączyć zapis w chmurze
      } else {
        this.$router.push("/");
      }
    });
  },
  beforeUnmount() {
    // console.log("Zatrzymuj zegar");
    clearInterval(this.timerInterval);
    console.log("clear interval");
    this.disabledColors = [];
  },
  methods: {
    async getUserData() {
      const db = firebase.firestore();
      const ref = db.collection("users").doc(this.$store.state.currentUser.uid);
      const doc = await ref.get();
      if (!doc.exists) {
        console.log("No such document!");
        this.$router.push("/games");
      } else {
        if (doc.data().games.game4.active == false || doc.data().games.game4.gameComplete == true) {
          this.$router.push("/games");
        }
        if (doc.data().games.game4.bears) {
          console.log("Restore from save");
          this.bears = doc.data().games.game4.bears;
          this.gameComplete = doc.data().games.game4.gameComplete;
          this.userPoints = doc.data().games.game4.userPoints;
          if (doc.data().games.game4.startDateTime) {
            this.startDateTime = doc.data().games.game4.startDateTime;
          }
        } else {
          console.log("Save not exist!");
        }
      }
    },
    async updateUserData() {
      const gameData = {
        name: "W Karpaczu MISIE podoba",
        url: "/game-4",
        active: true,
        bears: this.bears,
        gameComplete: this.gameComplete,
        userPoints: this.userPoints,
        startDateTime: this.startDateTime,
      };

      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.$store.state.currentUser.uid)
        .update({
          "games.game4": gameData,
        });
    },
    getDiploma() {
      let storage = firebase.storage();
      var pathReference = storage.ref('dyplom.jpg');

      pathReference.getDownloadURL().then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          console.log(event);
          var blob = xhr.response;
          console.log(blob);
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'dyplom';
          link.click();
          URL.revokeObjectURL(link.href);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        console.log(error);
      });
    },
    async startGame() {
      this.gameStarted = true;

      this.startDateTime = new Date();

      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.$store.state.currentUser.uid)
        .update({
          "games.game4.startDateTime": this.startDateTime,
        });
      
      this.timerInterval = setInterval(() => {
        this.setTimer(this.startDateTime);
        console.log("start timer");
      }, 1000);
    },
    returnGame() {
      this.gameStarted = true;

      this.setTimer(this.startDateTime.toDate());
      this.timerInterval = setInterval(() => {
        this.setTimer(this.startDateTime.toDate());
        console.log("return timer");
      }, 1000);
    },
    setTimer(startTime) {
      let now = new Date();
      let diffDate = 7200000 - (now - startTime.getTime());

      // console.log(diffDate);
      if (diffDate <= 0) this.endGame();

      let ms = diffDate % 1000;
      diffDate = (diffDate - ms) / 1000;
      let sec = diffDate % 60;
      diffDate = (diffDate - sec) / 60;
      let min = diffDate % 60;
      let hrs = (diffDate - min) / 60;

      if (sec < 10) sec = "0" + sec;
      if (min < 10) min = "0" + min;
      if (hrs < 10) hrs = "0" + hrs;

      this.userTimer = hrs + ":" + min + ":" + sec;
    },
    addHoursToDate(date, hours) {
      return new Date(new Date(date).setHours(date.getHours() + hours));
    },
    selectBear(bear) {
      if (!bear.unlocked) this.selectedBear = bear;
    },
    answerBear(color) {
      if (this.selectedBear.color == color) {
        console.log("Prawidłowy kolor");
        let foundBearId = this.bears.findIndex(bear => bear.number == this.selectedBear.number);
        this.bears[foundBearId].unlocked = true;
        this.userPoints += 5;

        setTimeout(() => {
          if (this.selectedBear.number % 10 == 0) {
            this.selectedBonus = this.bonusQuestions.find(q => q.id == this.selectedBear.number);
            this.bonusQuestionActive = true;
          }
          
          this.selectedBear = null;
          this.disabledColors = [];

          let finishGame = this.bears.every(bear => bear.unlocked == true);
          if (finishGame) this.endGame();
        }, 2000);
      } else {
        this.userPoints -= 1;
        this.disabledColors.push(color);
      }
      this.updateUserData();
    },
    selectBonusAnswer(answer, index) {
      console.log(answer, index);
      const isCorrect =
        answer === this.selectedBonus.correctAnswer ? true : false;
      this.selectedAnswerIndex = index;

      if (isCorrect) {
        this.correct = true;
        this.wrongIndex = -1;
        this.userPoints += 10;
        setTimeout(() => {
          this.correct = false;
          this.selectedAnswerIndex = null;
          this.selectedBonus = null;
          this.bonusQuestionActive = false;
        }, 1000);
        this.updateUserData();
      } else {
        this.wrongIndex = index;
        setTimeout(() => {
          this.wrongIndex = -1;
          this.selectedAnswerIndex = null;
          this.selectedBonus = null;
          this.bonusQuestionActive = false;
        }, 1000);
      }
    },
    async endGame() {
      // console.log("Koniec gry", this.$store.state.currentUser.uid);
      // Zakończenie gry jeżeli czas 2h minie lub jeżeli znajdę wszystkie miśki przed czasem
      // clearInterval(this.timerInterval);
      clearInterval(this.timerInterval);

      this.gameComplete = true;
      let endDateTime = new Date();

      const gameData = {
        name: "W Karpaczu MISIE podoba",
        url: "/game-4",
        active: true,
        bears: this.bears,
        gameComplete: this.gameComplete,
        userPoints: this.userPoints,
        startDateTime: this.startDateTime,
        endDateTime: endDateTime
      };

      const db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.$store.state.currentUser.uid)
        .update({
          "games.game4": gameData,
        });

      
    },
    shuffleBears() {
      this.bears.sort(() => Math.random() - 0.5);
    },
    shuffleBonusQuestions() {
      this.bonusQuestions.forEach((bonus) => {
        bonus.correctAnswer = bonus.answers[0]; // Wybranie prawidłowej odpowiedzi pytania bonusowego
        bonus.answers.sort(() => Math.random() - 0.5); // Wymieszanie odpowiedzi
      });
    },
    allUnlocked() { // Function for testing
      this.bears.forEach(bear => {
        bear.unlocked = true;
      })
      this.endGame();
    }
  },
  watch: {
    selectedBear: function(val) {
      if (val == null) this.disabledColors = [];
    }
  },
  computed: {
    user() {
      if (this.$store.state.currentUser)
        return this.$store.state.currentUser;
      else
        return { displayName: "" }
    }
  }
}
</script>

<style scoped>
button {
  background: chocolate;
  border: 3px dashed rgb(158, 74, 13);;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}
header {
  width: 100%;
  height: 50px;
  background: rgb(255,68,35);
  background: linear-gradient(140deg, rgba(255,68,35,1) 0%, rgba(119,16,119,1) 35%, rgba(25,54,216,1) 100%);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: white;
}
header .back-btn a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
header label {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  margin-left: 0.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}
main {
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  background: white;
}
main > div {
  min-height: calc(100vh - 50px);
  padding: 1rem;
}
.intro-screen {
  background: url('/game4/bg2.jpg');
  background-size: cover;
  background-position: bottom;
  display: grid;
  place-content: center;
}
.intro-screen p {
  margin: 1rem 0;
}
.menu-screen {
  background: linear-gradient(#000, #222);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menu-screen h3 {
  margin-bottom: 1rem;
}
.question-screen {
  background: linear-gradient(#ffe7c3, #fff);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.end-screen {
  background: url('/game4/bg2.jpg');
  background-size: cover;
  background-position: bottom;
  display: grid;
  place-content: center;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
}
.bonus-screen {
  background: linear-gradient(#ffe7c3, #fff);
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
}
.map-screen {
  background: linear-gradient(#ffe7c3, #fff);
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
}
.bear-image {
  width: 60vw;
  height: 60vw;
  background: url('/game4/player.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0.5;
}
.bear-image h1,
.bear-text h1 {
  font-size: 26px;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px dashed rgb(53, 53, 53);
}
.nums {
  display: grid;
  grid-template: repeat(10, 1fr) / repeat(10,1fr);
  gap: .2rem;
  width: 90vw;
  height: 75vh;
  margin: 0 auto;
}
.nums > div {
  border: 1px solid transparent;
  border-radius: 50%;
  display: grid;
  place-items: center;
  height: calc(9vw - 3.2px);
}
.colors {
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(4, 1fr);
  gap: .5rem;
  place-items: center;
}
.colorBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 5px 10px 0 rgb(190, 190, 190);
  margin: 0;
}
.colorBtn.white {
  border: 2px solid #c7c7c7 !important;
}
.colorBtn:disabled {
  opacity: 0.2;
}
.red {
  background: #ff4423;
  color: #000;
  border: 2px solid #b9351d !important;
}
.red-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-red.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.green {
  background: #2fa82f;
  color: #000;
  border: 2px solid #2a852a !important;
}
.green-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-green.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.blue {
  background: #1936d8;
  color: white;
  border: 2px solid #172ba1 !important;
}
.blue-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-blue.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.violet {
  background: #771077;
  color: white;
  border: 2px solid #4b0a4b !important;
}
.violet-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-violet.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.brown {
  background: #a0522d;
  color: white;
  border: 2px solid #72381d !important;
}
.brown-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-brown.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.black {
  background: #000000;
  border: 2px solid #686868 !important;
  color: white;
}
.black-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-black.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.rose {
  background: #ee82ee;
  color: #000;
  border: 2px solid #c455c4 !important;
}
.rose-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-rose.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.yellow {
  background: #ffff00;
  color: #000;
  border: 2px solid #cccc00 !important;
}
.yellow-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-yellow.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.orange {
  background: #ffa500;
  color: #000;
  border: 2px solid #d38900 !important;
}
.orange-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-orange.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.gray {
  background: #9e9e9e;
  color: #000;
  border: 2px solid #868686 !important;
}
.gray-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-gray.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.white {
  background: #ffffff;
  color: #000;
  border: 2px solid #868686 !important;
}
.white-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/player.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.gold {
  background: #bb8f00;
  color: #000;
  border: 2px solid #775b00 !important;
}
.gold-bear {
  width: 60vw;
  height: 60vw;
  background: url('/game4/bear-gold.png');
  background-size: cover;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.q-box {
  margin: 2rem 0;
}
.answer-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  z-index: 1;
  margin: 1rem 0;
}
.answerButton {
  border-radius: .5rem;
  height: 70px;
  display: grid;
  place-items: center;
  transition: 0.3s all;
  background: chocolate;
  border: 3px dashed rgb(158, 74, 13);;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}
.correct {
  background: #5bc236 !important;
}
.correct-text {
  color: #5bc236 !important;
}
.wrong {
  background: tomato !important;
}
.wrong-text {
  color: tomato !important;
}
.bigger {
  transform: scale(1.05);
}
</style>